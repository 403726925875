import React from 'react'

export const VercelIcon = ({ className, ...props }) => (
  <svg
    {...props}
    className={`text-vercel ${className}`}
    role="img"
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Vercel</title>
    <path d="M24 22.525H0l12-21.05 12 21.05z" />
  </svg>
)
